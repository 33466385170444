.levelGroup p {
  margin: 0;
}

.configWrapper {
  width: 100%;
  display: flex;
}

.levelTitle {
  display: flex;
  align-items: center;
}

.levelTitle p {
  width: 180px;
}
.levelTitle p:nth-of-type(even) {
  margin-left: 20px;
}

.mWidth {
  min-width: 80px;
}

.levelData {
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  padding: 10px 0;
}

.levelData :nth-of-type(odd) {
  /* margin-right: 10px; */
}

.levelData :nth-of-type(even) {
  /* margin-left: 10px; */
}

.ml-space {
  margin-left: 10px;
}

.dataBox {
  width: 180px;
  height: 100%;
  padding: 10px 6px;
  background-color: #eee;
  border: 1px solid;
  border-radius: 2px;
}
