.search-item {
  width: 15%;
}
.search-item.range {
  width: 25%;
}

.search-btn-group {
  display: flex;
  align-items: center;
}
.ml-16 {
  margin-left: 16px;
}

@media screen and (min-width: 800px) and (max-width: 1280px) {
  .search-item {
    width: 50%;
  }
  .search-item.range {
    width: 50%;
  }
}

.search-item label {
  min-width: unset;
}
.flex-box {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.tab-wrapper {
  flex: 1;
}

.tab-wrapper .ant-tabs-content {
  height: 100%;
}

.team-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.tab-container {
  flex: 1;
}

.picGroup {
  width: 100%;
}

.flex-start {
  display: flex;
  align-items: center;
}

.flex-start > span {
  display: inline-block;
  min-width: 100px;
}

.hidden{
  display: none;
}