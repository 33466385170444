.header {
    padding: 19px 0 17px 20px;
    font-size: 18px;
    font-weight: bold;
    color: rgba(51, 51, 51, 1);
    background-color: #fff;
}

.container-content {
    height: 100%;
    padding: 20px;
    background-color: rgba(230, 235, 242, 1);
}

.container-content>div {
    border-radius: 8px;
    height: calc(100vh - 166px);
    padding: 44px 16px 0 16px;
    position: relative;
    background-color: #fff;
}

.bg-item {
    height: 79px;
    width: 126px;
    margin-bottom: 12px;
    border-radius: 5px;
}

.bg-container {
    padding: 3px;
    border-radius: 8px;
    box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.08);
}

.bg-red {
    background: linear-gradient(180deg, rgba(255, 38, 38, 1) 0%, rgba(255, 112, 64, 1) 100%);
}
.bgFont-red>div:first-child {
    font-size: 14px;
    font-weight: bold;
    color: rgba(255, 85, 51, 1);
}

.bgFont-red>div:last-child {
    font-size: 12px;
    color: rgba(255, 85, 51, 1);
}

.bg-blue {
    background: linear-gradient(180deg, rgba(38, 92, 255, 1) 0%, rgba(64, 160, 255, 1) 100%);
}
.bgFont-blue>div:first-child {
    font-size: 14px;
    font-weight: bold;
    color: rgba(51, 136, 255, 1);
}

.bgFont-blue>div:last-child {
    font-size: 12px;
    color: rgba(51, 136, 255, 1);
}

.bg-orange {
    background: linear-gradient(180deg, rgba(255, 134, 13, 1) 0%, rgba(255, 165, 38, 1) 100%);
}
.bgFont-orange>div:first-child {
    font-size: 14px;
    font-weight: bold;
    color: rgba(255, 134, 13, 1);
}

.bgFont-orange>div:last-child {
    font-size: 12px;
    color: rgba(255, 134, 13, 1);
}

.bg-red2 {
    background: linear-gradient(180deg, rgba(255, 38, 110, 1) 0%, rgba(255, 77, 151, 1) 100%);
}
.bgFont-red2>div:first-child {
    font-size: 14px;
    font-weight: bold;
    color: rgba(255, 38, 111, 1);
}

.bgFont-red2>div:last-child {
    font-size: 12px;
    color: rgba(255, 38, 111, 1);
}

.bg-purple {
    background: linear-gradient(180deg, rgba(83, 34, 230, 1) 0%, rgba(199, 46, 230, 1) 100%);
}
.bgFont-purple>div:first-child {
    font-size: 14px;
    font-weight: bold;
    color: rgba(83, 34, 230, 1);
}

.bgFont-purple>div:last-child {
    font-size: 12px;
    color: rgba(83, 34, 230, 1);
}
 
.selectbg-container .ant-radio-wrapper {
    align-items: flex-start;
    margin-right:36px ;
    margin-bottom: 46px;
}


.selectbg-container .ant-radio{
    margin-right: 5px;
}

.selectbg-container {
    margin-left: 80px;
    display: flex;
    justify-content: flex-start;
}

.title{
    padding-right: 16px;
    font-size: 14px;
    font-weight: bold;
    color: rgba(51, 51, 51, 1);
}
.module-container{
    margin-left: 42px;
    display: flex;
    justify-content: flex-start;
}
.submit-button{
    margin-left: -94px;
    position: absolute;
    bottom: 60px;
    left: 50%;
} 
.submit-button .ant-btn-lg{
    width: 188px;
    height: 44px;
}

.module-container .ant-checkbox-wrapper{
    margin-right: 50px;
}

.module-container .ant-checkbox{
    top: -0.8em;
}
.module-item{
    text-align: center;
}
.module-item>div:last-child{
    font-size: 12px;
    color: rgba(153, 153, 153, 1);
}

/* .content{
   

} */