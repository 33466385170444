html,
body {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
}

.loginContent {
    width: 100%;
    height: 100%;
    background: url(../assets/images/logo_bg.jpg) no-repeat center center;
    background-size: cover;
}

.loginTitle {
    position: absolute;
}

.logo-form {
    position: absolute;
    top: calc(50% - 175px);
    left: calc(50% - 275px);
    width: 550px;
    /* height: 350px; */
    border-radius: 5px;
    background: #ffffff;
    box-shadow: 0 0 5px rgba(0, 0, 0, .3);
    padding: 45px 50px;
    box-sizing: border-box;
}

.logo-tit {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: #424242;
    font-size: 30px;
    margin-bottom: 30px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding-bottom: 10px;
    /*text-align: center;*/
}

.logo-tit span {
    margin-left: 10px;
}

.formContent {
    width: 100%;
}

.logo-item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    margin-bottom: 25px;
}

.logo-item label {
    display: flex;
    align-items: center;
    width: 70px;
    color: #424242;
}

.logo-item .ant-form-item-control {
    width: calc(100% - 70px);
}

.logo-item .ant-input {
    width: 100%;
    height: 35px;
    border: 1px solid #eeeeee;
    border-radius: 3px;
    padding: 5px 8px;
    box-sizing: border-box;
    letter-spacing: 1px;
    font-size: 14px;
    outline: none;
    box-shadow: 0 0 10000px #ffffff inset;
}

.logo-item .ant-input:focus {
    border: 1px solid rgb(3 169 244 / 40%);
    box-shadow: 0 0 10000px #ffffff inset;
}

.logo-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.logo-btn button {
    width: 100%;
    height: 35px;
    color: #ffffff;
    font-size: 16px;
    border-radius: 3px;
    border: 0;
    background: #03a9f4;
    cursor: pointer;
    margin-bottom: 10px;
}

.logo-btn button span {
    margin-right: 5px;
}

.logo-btn .ant-btn-loading {
    opacity: .5;
    cursor: no-drop;
}

.login-logo {
    width: 87px;
    height: 34px;
    /*background: url(../assets/images/logo2.png) no-repeat center;*/
    background-size: 100% 100%;
}
.logo-nav{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 60px;
}
.logo-nav div:nth-child(1){
    margin-right: 30px;
}

.nav-password{
    font-size: 20px;
    color: rgba(153, 153, 153, 1);
}
.nav-password--active{
    font-size: 28px;
    color: #333;
    font-weight: 500;
}
.nav-verifycode{
    font-size: 20px;
    color: rgba(153, 153, 153, 1);
}
.nav-verifycode--active{
    font-size: 28px;
    color: #333;
    font-weight: 500;
}
.verifyCode-inputbox{
    position: relative;
}
.verifyCode-input{
    height: 40px;
}
.verifyCode-button{
    position: absolute;
    right: 6px;
    top: 50%;
    margin-top: -16px;
}

.verifyCode-button .ant-btn.ant-btn-loading{
    position: none;
}
.userName-input{
    margin-bottom: 30px;
}

.submit{
    margin-top: 86px;
}

.submit .ant-btn-block{
    height: 40px;
}
