.taskListFilterWrap {
  width: 100%;
  height: 90px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.tableWrap {
  width: 100%;
}
.uploading-span {
  width: 100px;
  height: 100px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.uploading-fail-span {
  width: 100px;
  height: 100px;
}
.ddlktaskmaterialPic {
  width: 100px;
  height: 100px;
  margin-right: 10px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.ddlktaskWrap {
  display: flex;
  flex-direction: row;
}
.ddlktaskmaterialvideo {
  width: 100px;
  height: 100px;
  background-color: #e1e1e1;
  margin-right: 10px;
  margin-bottom: 10px;
  display: flex;
}
.materialClear {
  width: 18px;
  height: 18px;
  color: red;
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
  zindex: 9999 !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.materialClear:hover {
  background: red;
  color: white;
}
.ddlktaskmaterialfail {
  width: 100px;
  height: 100px;
  background-color: #e1e1e1;
  margin-right: 10px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ant-card-body {
  width: 100%;
}
